export default {
  accept_terms_advertiser: 'I\'ve read and accept <a href="/advertising-and-refund-policy" target="_blank">Advertising and refund policy</a>',
  accept_terms: 'I\'ve read and accept <a href="{terms_and_conditions_url}" target="_blank">Terms & Conditions</a> and <a href="{privacy_policy_url}" target="_blank">Privacy Policy</a>',
  access_denied: 'Access denied',
  actions: 'Actions',
  active_male: 'Active',
  activity: 'Activity',
  add: 'Add',
  admin: 'Admin',
  adsecure: 'AdSecure',
  advertiser_balance: 'Advertiser balance',
  advertiser_statistics: 'Adv. statistics',
  advertiser: 'Advertiser',
  advertisers_activity_short: 'Adv. activity',
  advertisers_activity: 'Advertisers activity',
  advertisers_cpc: 'Adv. CPC',
  advertisers_payout: 'Adv. $',
  advertisers: 'Advertisers',
  advertising_and_refund_policy: 'Advertising and refund policy',
  alias: 'Alias',
  all: 'All',
  allow_campaigns: 'Allow campaigns',
  allow_feeds: 'Allow feeds',
  allow_referrer_mismatch: 'Allow ref. mismatch',
  amount: 'Amount',
  any_male: 'Any',
  api_token: 'API token',
  api: 'API',
  apply: 'Apply',
  approval_status: 'Approv. status',
  approve: 'Approve',
  approved_male: 'Approved',
  archive: 'Archive',
  authorizations: 'Authorizations',
  auto_approve_campaings: 'Аuto approve campaigns',
  autofilling: 'Autofilling',
  available_endpoints: 'Available endpoints',
  available_feeds: 'Available feeds',
  available_macros: 'Available macros',
  balance: 'Balance',
  banned_male: 'Banned',
  bid_is_string_help: 'Use if bid is string, for example, "0.0135" instead of 0.0135',
  bid_is_string: 'Bid is string',
  bid_may_be_zero_help: 'For companies like Adsterra, ZeroPark 🤡',
  bid_may_be_zero: 'Bid = 0 - is ok',
  bid_path: 'Bid path',
  bid_subject_click: 'Per click',
  bid_subject_impression: 'Per impression',
  bid_subject: 'Bid subject',
  bid_type_help: 'Don\'t mix it up! 👆',
  bid_type: 'Bid type',
  bid: 'Bid',
  bidder: 'Bidder',
  black_list: 'Blacklist',
  block: 'Block',
  bounces: 'Bounces',
  browser: 'Browser',
  browsers: 'Browsers',
  campaign_connection_type_all: 'All',
  campaign_connection_type_carrier: 'Carrier',
  campaign_connection_type_non_carrier: 'Only Wi-Fi',
  campaign_connection_type: 'Connection type',
  campaign: 'Campaign',
  campaigns_activity_short: 'Camp. activity',
  campaigns_activity: 'Campaigns activity',
  campaigns_allowed: 'Campaigns allowed ✅',
  campaigns: 'Campaigns',
  canceled_male: 'Canceled',
  capitalist: 'Capitalist',
  carrier: 'Carrier',
  carriers: 'Carriers',
  category: 'Category',
  change_subid_status_to_approved: 'Change subid status to "Approved"',
  change_subid_status_to_declined: 'Change subid status to "Declined"',
  change_subid_status_to_new: 'Change subid status to "New"',
  chars: 'chars',
  checker: 'Checker',
  checking: 'Checking',
  clear: 'Clear',
  click_host_pop_sensitive: 'Click-domain for sensitive Pop traffic 🙀',
  click_host_pop: 'Click-domain for Pop traffic',
  click_host_push_sensitive: 'Click-domain for sensitive Push traffic 🙀',
  click_host_push: 'Click-domain for Push traffic',
  click_not_found: 'Click not found',
  click_url_key: 'Click URL key',
  click_url_or_payload: 'Click URL or value of &payload= param',
  click_url_path: 'Click URL path',
  click_url: 'Click URL',
  clicks: 'Clicks',
  clone: 'Clone',
  coinbase: 'Coinbase',
  comma: 'Comma',
  comment: 'Comment',
  common_settings: 'Common settings',
  completed_male: 'Completed',
  connect_adult: 'Connect non-mainstram 🍓',
  connect_all: 'Connect all',
  connect_mainstream: 'Connect mainstream',
  connect: 'Connect',
  connected_endpoints: 'Connected endpoints',
  connected_feeds: 'Connected feeds',
  connection: 'Connection',
  contact_information: 'Contact information',
  contact_via_form: 'If you have any questions don\'t hesitate to contact us via contact form',
  conversions_payout: 'Conv. $',
  conversions_short: 'Conv.',
  conversions: 'Conversions',
  convert: 'Convert',
  copied_to_clipboard: 'Copied to clipboard',
  copy_female: 'Copy',
  copy_login_url_detail: 'TTL of login URL is 1 hour 👆',
  copy_login_url: 'Copy login URL',
  copy: 'Copy',
  countries: 'Countries',
  country: 'Country',
  cpc_by_countries: 'CPC by countries 🌐',
  cpc_by_subids_uuids: 'CPC by subids UUIDs',
  cpc_by_zones_uids: 'CPC by zones UIDs',
  created_at: 'Created at',
  creative_type_builtin: 'One built-in creative (Will be removed soon)',
  creative_type_multiple: 'Rotation of creatives',
  creative_type: 'Creative type',
  creative: 'Creative',
  creatives: 'Creatives',
  crid: 'CrID',
  current_page_report_template: 'Showing [first] to [last] of [totalRecords]',
  custom_click_host: 'Custom click-domain',
  custom_response_provider: 'Custom response provider',
  customizer: 'Customizer',
  dark_theme: 'Dark theme',
  dashboard: 'Dashboard',
  data_elements_selectors_help: 'Also you can use option <i>data-elements-selectors</i>, example: <i>data-elements-selectors="a.some-element-class,#some-element-id"</i>,<br>for more details see <a href="https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelectorAll" target="_blank">https://developer.mozilla.org/en-US/docs/Web/API/Document/querySelectorAll</a>',
  data_in_attributes: 'Data in attributes',
  date_from: 'Date from',
  date_to: 'Date to',
  date: 'Date',
  days: 'Days',
  decline: 'Decline',
  declined_male: 'Declined',
  declined_subids: 'Declined subids',
  decode_payload: 'Decode Payload 🤖',
  default_revshare_advertiser: 'Default RevShare (Advertiser)',
  delay_to_first_sending: 'Delay to first sending',
  delay_to_sending: 'Delay to sending',
  delete: 'Delete',
  description_path: 'Description path',
  description: 'Description',
  desktop: 'Desktop',
  dev_host: 'Dev. domain',
  direct_clicks: 'Direct clicks',
  direct_publisher: 'Direct publisher',
  disable_decline_non_effective_subids: 'Don\'t decline subids by non effective requests optimization',
  disable: 'Disable',
  disabled_male: 'Disabled',
  disconnect_adult: 'Disconnect non-mainstream 🍓',
  disconnect_all: 'Disconnect all',
  disconnect_mainstream: 'Disconnect mainstream',
  disconnect: 'Disconnect',
  done: 'Done',
  download_button: '"Download" button',
  download_csv: 'Download CSV',
  duplicate: 'Duplicate',
  edit: 'Edit',
  efficiency: 'Eff.',
  email: 'Email',
  empty: 'Empty',
  enable_pay_by_card: 'Enable pay by card 💳',
  enable: 'Enable',
  enabled_male: 'Enabled',
  endpoint_override_project_settings: '🛠 Override project settings',
  endpoint: 'Endpoint',
  endpoints: 'Endpoints',
  error: 'Error',
  exclude: 'Exclude',
  experimental_feature: 'Experimental feature 🧪',
  expired_male: 'Expired',
  expires_at: 'Expires at',
  extra_amount_comment: 'Extra amount comment',
  extra_amount: 'Extra amount',
  extra_hosts: 'Extra hosts',
  extra_hosts_help: '1 line - 1 host',
  failed_jobs: 'Failed jobs',
  fast: 'Standart',
  fast_help: 'Impressions are delivered until the daily budget is exhausted, focusing on speed.',
  feed_api_provider: 'Feed API provider',
  feed: 'Feed',
  feed_payout: 'Feed $',
  feeds_activity_short: 'Feeds activity',
  feeds_activity: 'Feeds activity',
  feeds_allowed: 'Feeds allowed ✅',
  feeds_cpc: 'Feeds CPC',
  feeds_payout: 'Feeds $',
  feeds_plus_advertisers_plus_conversions_payout: 'Revenue Σ',
  feeds: 'Feeds',
  feeds_creatives: 'Feeds Creatives',
  file_size_too_big: 'File size too big',
  filter_click_without_impression: 'Click w/o imp.',
  filter_double_click: 'Double click',
  filter_has_no_js: 'No JS',
  filter_headless: 'Headless',
  filter_iframe: 'iframe',
  filter_ip_mismatch: 'IP mismatch',
  filter_not_checked: 'Dropped',
  filter_proxy: 'Proxy',
  filter_referrer_mismatch: 'Ref. mismatch',
  filter_timezone_mismatch_help: 'Browser timezone is different from country timezone',
  filter_timezone_mismatch: 'TZ mismatch',
  filter_user_agent_mismatch: 'UA mismatch',
  filtered_clicks: 'Filtered clicks',
  filtered_direct_clicks: 'Filtered direct clicks',
  filtered_rtb_clicks: 'Filtered RTB clicks',
  filtered: 'Filtered',
  filters_and_metrics: 'Filters & metrics',
  filters_too: 'Filters too',
  filters: 'Filters',
  firstname: 'Firstname',
  fixed_bid_by_countries: 'Fixed bid by countries 🌐',
  fixed_bid_help: 'Per one click or impression 👆',
  fixed_bid: 'Fixed bid',
  fixed_conversion_payout: 'Fixed conversion $',
  for_3_days: 'For 3 days',
  for_last: 'for last',
  generate_password: 'Generate password',
  generate: 'Generate',
  get_ip_and_user_agent_from_headers: 'Get IP and User-Agent from headers',
  get_url: 'Get URL',
  has_cookies: 'Has cookies',
  has_js: 'Has JS',
  has_social_auth: 'Auth',
  has_violations: 'Has violations 🚨',
  headless: 'Headless',
  hide_pseudosite_host_in_click_url: 'Hide pseudosite host in Click URL',
  host_help: 'Without https://, lowercase, example: site.com, www.site.com',
  host: 'Domain',
  hour: 'Hour',
  hours_abbr: 'hrs.',
  hours_interval: 'Hours interval',
  hours: 'Hours',
  icon_url_path: 'Icon URL path',
  icon: 'Icon',
  iframe: 'iframe',
  image_host: 'Image-domain',
  image_url_path: 'Image URL path',
  image: 'Image',
  impressions: 'Impressions',
  include: 'Include',
  interval: 'Interval',
  invalid_email_or_password: 'Invalid email or password',
  invalid_payout: 'Invalid payout',
  invalid_request: 'Invalid request',
  ip_too: 'IP too',
  ips_help: 'One IP range per line, example:<br>100.12.0.0/32<br>172.16.0.0–172.31.255.255',
  ips: 'IP ranges',
  is_builtin_female: 'Built-in ⚙️',
  is_builtin: 'Built-in ⚙️',
  items_in_array_help: 'Use if items in array, for example, "[&#123;"bid": 0.0135, ...&#125;]" instead of &#123;"bid": 0.0135, ...&#125;',
  items_in_array: 'Items in array',
  items_path: 'Items path',
  jobs: 'Jobs',
  key: 'Key',
  languages: 'Languages',
  lastname: 'Lastname',
  light_theme: 'Light theme',
  link: 'Link',
  links: 'Links',
  load: 'Load',
  login_at: 'Login at',
  login_error: 'Login failed',
  login: 'Login',
  low_roi: 'Low ROI',
  macros_subid_id_help: 'Assigned ID like 100500',
  macros_subid_uuid_help: 'Assigned UUID like ce082adf-4a13-4e68-acae-b426cfaa238e',
  main: 'Main',
  manager_contact_information: 'Manager contact information',
  manager: 'Manager',
  manually: 'Manually',
  max_age: 'Max age',
  max_bid_native: 'Max bid for Native traffic',
  max_bid_pop: 'Max bid for Pop traffic',
  max_bid_push: 'Max bid for Push traffic',
  max_bid: 'Max bid',
  max_clicks_filtered_by_has_no_js_percent: 'No JS <=',
  max_clicks_filtered_by_headless_percent: 'Headless <=',
  max_clicks_filtered_by_iframe_percent: 'iframe <=',
  max_clicks_filtered_by_not_checked_percent: 'Dropped <=',
  max_clicks_filtered_by_proxy_percent: 'Proxy <=',
  max_clicks_filtered_percent: 'Filtered <=',
  max_clicks_per_ip_help: '7 days, 0 - unlimited',
  max_clicks_per_ip: 'Max clicks per IP',
  max_file_size: 'Max. file size',
  max_length: 'Max length',
  max_qps_help: '0 - unlimited 💦',
  max_qps: 'Max QPS',
  max_revenue_per_day_help: '0 - unlimited',
  max_revenue_per_day: 'Max $ per day',
  max_sendings: 'Max sendings',
  max_sites: 'Max. sites',
  max_spending_help: '0 - unlimited',
  max_spending_is_reached: 'Max spending limit is reached',
  max_spending_per_day_help: '0 - unlimited',
  max_spending_per_day_strategy: 'Delivery method',
  max_spending_per_day: 'Max $ per day',
  max_spending_per_endpoint_per_day_help: '0 - unlimited',
  max_spending_per_endpoint_per_day: 'Max $ per zone per day',
  max_spending: 'Max $',
  max_subids_per_day: 'Max subids per day',
  meta_refresh: 'Meta refresh',
  method: 'Method',
  balance_overdraft: 'Balance overdraft',
  min_bid_native: 'Min bid for Native traffic',
  min_bid_pop: 'Min bid for Pop traffic',
  min_bid_push: 'Min bid for Push traffic',
  min_bid: 'Min bid',
  min_clicks_has_cookies_percent: 'Has cookies >=',
  min_clicks_has_social_auth_percent: 'Auth >=',
  min_clicks_landing_ctr_good: 'CTR >=',
  min_ucfirst: 'Min.',
  min: 'Min.',
  mix_traffic_type: 'Mix traffic type',
  mobile: 'Mobile',
  mode: 'Mode',
  ms: 'ms.',
  name: 'Name',
  new_line: 'New line',
  new_male: 'New',
  new_password: 'New password',
  new_subids: 'New subids',
  no_ads: 'No ads',
  nothing: 'Nothing 😢',
  nurl_path: 'nURL path',
  only_mainstream_coverage: 'Only Mainstream coverage 👶',
  options: 'Options',
  page_not_found: 'Page not found 😢',
  paid_at: 'Paid at',
  paid_clicks: 'Paid',
  passback_forbidden_traffic: 'Passback forbidden traffic',
  passback_url_adult: 'Passback URL for non-mainstream 🍓 traffic',
  passback_url_mainstream: 'Passback URL for mainstream traffic',
  passback_url: 'Passback URL',
  password_will_be_encrypted: 'Password will be encrypted 👌',
  password: 'Password',
  pay_filtered_click_chance: 'Chance of pay filtered click',
  pay: 'Pay',
  payable_views: 'Payable imp.',
  payment_info: '<b>Payment method:</b><br>Sorry for the inconvenience, more payment methods coming soon. Please use:<br><b>Bitcoin:</b> bc1qy5dydm3nn75ccw23w6l309psd5aluq2xn90mjk<br><b>USDT (TRC-20):</b> TFaMU8fwdXC8wX3J2g5qnswwqDRdrETCD2',
  payment_type: 'Payment type',
  payment: 'Payment',
  payments: 'Payments',
  payout_information_from_user_profile: 'Payout information from user profile 👆',
  payout_information: 'Payout information',
  payout_period: 'Publisher payout period',
  payout_terms_weekly: 'Payout Terms:<br>Minimum payout amount: $100.<br>Payout schedule: every monday.',
  payout_terms: 'Payout Terms:<br>Minimum payout amount: $100.<br>Payout schedule: 1st and 16th of each months.',
  payout: 'Payout',
  payouts_amount: 'Payouts amount',
  payouts: 'Payouts',
  pending_approval: 'Pending approval',
  pending_male: 'Pending',
  period: 'Period',
  platform: 'OS',
  platforms: 'OS',
  pop_bid_is_too_high: '⚠️ Make sure that your CPC bid is correct, the specified value is too high for the Pop',
  pop_button_blue: 'Blue arrow',
  pop_button_cog: 'Cog in iOS style',
  pop_button_green: 'Green arrow',
  pop_button_rotator: 'Rotation',
  pop_code: 'JS pop code',
  pop_frequency_seconds_help: '0 - unlimited',
  pop_frequency_seconds: '1 impression per',
  postback_url: 'Postback URL',
  postback: 'Postback',
  powered_by: 'Powered by',
  preview_on_theme_color_change: 'Preview on theme color change',
  preview: 'Preview',
  processed: 'Processed',
  profile: 'Profile',
  profit_by_days: 'Profit for the last 5 days',
  profit: 'Profit',
  project: 'Project',
  projects: 'Projects',
  promo: 'Promo',
  promocode: 'Promocode',
  protocol_direct_link: 'Direct Link',
  protocol_legacy: 'XML or JSON',
  protocol_openrtb: 'OpenRTB',
  protocol: 'Protocol',
  proxy_requests: 'Proxy requests',
  pseudosite: 'Pseudosite',
  pseudosites: 'Pseudosites',
  publisher_payout_information: 'Publisher payout information',
  publisher_statistics: 'Pub. statistics',
  publisher: 'Publisher',
  publishers_activity_short: 'Publishers activity',
  publishers_activity: 'Publishers activity',
  publishers_cpc: 'Pub. CPC',
  publishers_payout: 'Pub. $',
  publishers: 'Publishers',
  push_code: 'JS push code',
  push_host: 'Push-domain',
  quality_preset: 'Quality preset',
  quality_presets: 'Quality presets',
  queued: 'Queued',
  ref_link: 'Ref. link',
  referrer_links: 'Ref. links',
  referrer_spoofing_host_help: 'Use for <b>force</b> override referrer host in request URL<br>Just domain, without https://',
  referrer_spoofing_host: 'Ref. spoofing host 🥷',
  referrer_spoofing: 'Ref. spoofing',
  referrer: 'Referrer',
  referrers_hosts_help: '1 line - 1 host',
  referrers_hosts: 'Referrers hosts',
  referrers: 'Referrers',
  region_as: 'Asia',
  region_eu: 'Europe',
  region_us: 'USA',
  region: 'Region',
  regions: 'Regions',
  registrations: 'Registrations',
  repeat_password: 'Repeat password',
  replace_feeds_responses: 'Replace feeds responses',
  replace: 'Replace',
  requests_optimization_delay_to: 'Delay requests optimization',
  requests: 'Requests',
  reset: 'Reset',
  revenue_advertisers: 'Revenue by advertisers',
  revenue_feeds: 'Revenue by feeds',
  revenue_publishers_direct: 'Revenue by direct publishers',
  revenue_publishers_rtb: 'Revenue by RTB publishers 🧩',
  revenue: 'Revenue',
  revshare: 'RevShare',
  role: 'Role',
  rotation: 'Rotation',
  rtb_clicks: 'RTB clicks',
  rtb_payouts: 'RTB Payouts',
  rtb_publisher: 'RTB publisher',
  rtb_publishers: 'RTB publishers',
  rtb_statistics: 'RTB Statistics',
  save: 'Save',
  schedule_by_days: 'Schedule by days',
  schedule_by_hours: 'Schedule by hours',
  scheme: 'Feeds parser',
  schemes: 'Feeds parsers',
  search: 'Search',
  search_text: 'Search text',
  sec: 'sec.',
  select_all: 'Select all',
  sensitive: 'Sensitive 🙀',
  separator: 'Separator',
  server_time: 'Server time',
  setting: 'Settings',
  shave_by_countries: 'Shave by countries',
  shave: 'Shave',
  show_ad_id: 'Show ad_id',
  show_bounces: 'Show bounces',
  show_campaigns: 'Show campaigns',
  show_extra_data: 'Show extra data',
  show_feeds_requests: 'Show feeds requests statistics 🐢',
  show_feeds: 'Show feeds',
  show_requests: 'Show requests statistics 🐢',
  show_unique_clicks: 'Show unique clicks % 🐢',
  show: 'Show',
  sign_up: 'Sign Up',
  signup_at: 'Signup at',
  site: 'Site',
  sites: 'Sites',
  smoothly: 'Distributed',
  smoothly_help: 'Impressions are distributed evenly to provide consistent delivery and prevent early budget exhaustion.',
  spending_by_days: 'Spending for the last 5 days',
  spent: 'Spent',
  sum: 'Sum',
  ssp_host: 'SSP-domain',
  statistics_by_subids: 'Subids',
  statistics: 'Statistics',
  status: 'Status',
  stripe: 'Card',
  subid_id: 'Subid ID',
  subid_status_declined: 'Declined ☠️',
  subid_uuid: 'Subid UUID',
  subid: 'Subid',
  subids_checking_delay_to: 'Delay subids checking',
  subids_uuids_help: '1 line - 1 UUID',
  subids_uuids: 'Subids UUIDs',
  subids: 'Subids',
  subscriber_age_10: '0 - 10 days',
  subscriber_age_3: '0 - 3 days',
  subscriber_age_30: '0 - 30 days',
  subscriber_age: 'Subscriber age',
  success_campaign_approval_status_change: 'Campaign approval status successfully changed',
  success_campaign_status_change: 'Campaign status successfully changed',
  success_creative_approval_status_change: 'Creative approval status successfully changed',
  success_delete: 'Successfully deleted',
  success_store: 'Successfully saved',
  success_subid_status_change: 'Subid status successfully changed',
  success: 'Success',
  super_admin: 'Super Admin',
  support: 'Support',
  system: 'System',
  targeting_by_pseudosites: 'Targeting by pseudosites',
  targeting_settings: 'Targeting',
  targeting_type: 'Targeting type',
  test_in_progess: 'Test in progress',
  test_is_done: 'Test is done',
  test_max_spending_per_endpoint: 'Max $ per test per zone',
  test_max_spending_per_subid: 'Max $ per test per subid',
  test_max_spending: 'Max $ per test',
  test: 'Test',
  theme_color: 'Theme color',
  timeout: 'Request timeout',
  title_path: 'Title path',
  title: 'Title',
  tools: 'Tools',
  top_up_balance: 'Top-up balance',
  total: 'Total',
  traffic_category_adult: 'Non-mainstream 🍓',
  traffic_category_mainstream: 'Mainstream',
  traffic_category: 'Traffic category',
  traffic_source_direct: 'Direct 💎',
  traffic_source_rtb: 'RTB 🧩',
  traffic_source: 'Traffic source',
  traffic_type_banner: 'Banner',
  traffic_type_in_page_push: 'In-page Push',
  traffic_type_native: 'Native',
  traffic_type_pop: 'Pop',
  traffic_type_push: 'Push',
  traffic_type_video: 'Video',
  traffic_type: 'Traffic type',
  ttl_native: 'Session TTL for Native traffic',
  ttl_pop: 'Session TTL for Pop traffic',
  ttl_push: 'Session TTL for Push traffic',
  ttl: 'Click URL TTL',
  type: 'Type',
  ui_advertiser: 'Show "Advertiser" menu',
  ui_contact_notification_message: 'Please contact your manager to get fast approval and assist',
  ui_contact_notification_via: 'Contact via',
  ui_contact_notification: 'Show contact notification for advertiser',
  ui_publisher_direct: 'Show "Publisher" menu',
  ui_publisher_rtb: 'Show "RTB Publisher" menu',
  ui_referrer: 'Show "Promo" menu',
  ui_settings: 'UI settings',
  unarchive: 'Unarchive',
  unique: 'Unique',
  unknown_error: 'Unknown error',
  unknown: 'Unknown',
  unselect_all: 'Unselect all',
  unverified_male: 'Unverified',
  updated_at: 'Updated at',
  url_example: 'Example URL',
  url: 'URL',
  usage_type_mob: 'Carriers',
  use_quality_presets: 'Use quality presets',
  user_email: 'User email',
  user_id: 'User ID',
  user: 'User',
  users_report_has_no_sites: 'Has no sites',
  users_report_has_sites_and_has_no_traffic: 'Has sites & has no traffic',
  users_report_has_sites: 'Has sites',
  users_report_registered: 'Registered',
  users_report_runned_and_stopped: 'Runned & Stopped',
  users_report_short: 'Users report',
  users_report_still_running: 'Still running (today statistics)',
  users_report: 'Users report 🕵️‍♂️',
  users: 'Users',
  validation_error: 'Validation error',
  version_targeting_help: 'Version targeting',
  views: 'Views',
  visible_to_all_male: 'Visible to all 👆',
  wallet_address: 'Wallet address',
  weight: 'Weight',
  conflicts: 'Conflicts',
  wi_fi: 'Wi-Fi',
  delete_feed_from_endpoint_whitelist: 'Delete feed from endpoint whitelist',
  add_endpoint_to_feed_whitelist: 'Add endpoint to feed whitelist',
  you_can_manage_payout_information: 'You can manage your payout information in',
  zone_uid: 'Zone UID',
  zone: 'Zone',
  zones_uids_help: '1 line - 1 UID',
  zones_uids: 'Zones UIDs',
  zones: 'Zones',
  endpoints_efficiency: 'Endpoints eff.',
  chains_diff: 'Chains diff',
};
